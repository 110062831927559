/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:598101d1-7106-4f2a-9a8e-7cb165414015",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qbvZP83rO",
    "aws_user_pools_web_client_id": "6gpchbvggooq1iq6odc3kum4u4",
    "oauth": {
        "domain": "moomonlinef01d86d7-f01d86d7-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.moommaternidadonline.com/",
        "redirectSignOut": "https://app.moommaternidadonline.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://xmvu4j36wraehieyecmovuhpiu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "moomonline23f6b4cda9b440d9a6d4faed739cc989124039-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
