import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth, Hub } from "aws-amplify";
// import {withAuthenticator} from 'aws-amplify-react';
import { Authenticator } from "aws-amplify-react";
import { createUser } from "./graphql/mutations";
import { listNotes, getUser } from "./graphql/queries";
import { Router, Route, Redirect } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import Home from "./pages/Home";
import Course from "./pages/Course";
import Landing from "./pages/Landing";
import Profile from "./pages/Profile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Navbar from "./components/Navbar";
import { UserContext } from "./components/UserContext";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

export const history = createBrowserHistory();

const fontTheme = createMuiTheme({
  typography: {
    fontFamily: ["Chilanka", "Chilanka", "Chilanka"].join(","),
  },
});

const App = () => {
  const [user, setUser] = useState(null);
  const [userGraphQl, setUserGraphQl] = useState({});
  const getUserData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    user ? setUser(user) : setUser(null);
    console.log("user", user);
    getUserInfo(user);
    //registerNewUser(user);
  };
  const isUserAdminFn = (localUser) => {
    let isUserAdmin = false;
    if (localUser.signInUserSession.accessToken.payload["cognito:groups"]) {
      isUserAdmin =
        localUser.signInUserSession.accessToken.payload["cognito:groups"][0] ===
        "Admin"
          ? true
          : false;
    }
    console.log("isUserAdmin: ", isUserAdmin);
    return isUserAdmin;
  };

  const getProfilePic = (localUser) => {
    let url = null;
    let picture;
    console.log("> getProfilePic ", localUser);
    if (localUser.signInUserSession.idToken.payload.picture) {
      picture = JSON.parse(localUser.signInUserSession.idToken.payload.picture);
      url = picture.data.url;
    }
    console.log("> getProfilePic url", url);
    return url;
  };
  const getGivenName = (localUser) => {
    let given_name = null;
    console.log("> getGivenName ", localUser);
    if (localUser.signInUserSession.idToken.payload.given_name) {
      given_name = localUser.signInUserSession.idToken.payload.given_name;
    }
    console.log("> getProfilePic given_name", given_name);
    console.log("> getProfilePic is this a mapped attribute?");
    return given_name;
  };
  const getemail = (localUser) => {
    let email = null;
    console.log("> getemail ", localUser);
    email = localUser.signInUserSession.idToken.payload?.email;
    console.log("> getProfilePic given_name", email);
    return email;
  };

  const onHubCapsule = (capsule) => {
    switch (capsule.payload.event) {
      case "signIn":
      case "cognitoHostedUI":
        console.log("signed in");
        getUserData();
        break;
      case "signUp":
        console.log("signed up");
        //getUserData();
        break;
      case "signOut":
        console.log("signed out");
        setUser(null);
        break;
      default:
        console.error("no caught case!");
        break;
    }
  };
  const getUserInfo = async (user) => {
    console.log("getuserinfo : ", user);
    console.log(user.signInUserSession.idToken.payload.sub);
    console.log(user.signInUserSession.idToken.payload.email); //problem will be for the signup event only, in which signinuser will be null/undefined
    try {
      const { data } = await API.graphql(
        graphqlOperation(getUser, {
          id: user.signInUserSession.idToken.payload.sub,
        })
      );
      let graphData = null;
      if (!data.getUser) {
        const createUserInput = {
          id: user.signInUserSession.idToken.payload.sub,
          name: user.signInUserSession.idToken.payload.email,
        };
        try {
          console.log("createUserInput", createUserInput);
          const { data } = await API.graphql(
            graphqlOperation(createUser, {
              input: createUserInput,
            })
          );
          console.log("data", data.createUser);
          graphData = data.createUser;
        } catch (error) {
          console.err("error registering user", error);
        }
      } else {
        graphData = data.getUser;
      }
      setUserGraphQl({
        ...graphData,
        admin: isUserAdminFn(user),
        picture: getProfilePic(user),
        given_name: getGivenName(user),
        email: getemail(user),
      });
      console.log("userGraphQl: ", userGraphQl);
    } catch (error) {
      console.log(error);
    }
  };
  const registerNewUser = async (eventData) => {
    console.log("> registerNewUser: ", eventData);
    const { data } = await API.graphql(
      graphqlOperation(getUser, {
        id: eventData.signInUserSession.idToken.payload.sub,
      })
    );
  };
  const handleSignout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("err signing out" + error);
    }
  };
  useEffect(() => {
    // Update the document title using the browser API
    //getUserData();
    Hub.listen("auth", onHubCapsule);
    console.log(process.env);
    if (process.env.NODE_ENV === "production") {
      console.log = () => {};
    } else {
      console.log("dev env");
    }
  }, []);
  useEffect(() => {
    console.log("render usegraphql:", userGraphQl);
    // Update the document title using the browser API
  }, [userGraphQl]);
  return (
    <>
      {user ? (
        <Router history={history}>
          <ThemeProvider theme={fontTheme}>
            {console.log(user)}
            {console.log("env1:", process.env.NODE_ENV)}
            <UserContext.Provider value={userGraphQl}>
              <Navbar user={user} handleSignout={handleSignout} />
              <Route exact path="/" component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/terms" component={TermsAndConditions} />
              <Route
                path="/courses/:courseId"
                component={({ match }) =>
                  userGraphQl.order.items.map((order) => {
                    if (order.id === match.params.courseId) {
                      return true;
                    } else {
                      return false;
                    }
                  }) ? (
                    <Course
                      courseId={match.params.courseId}
                      userCognito={user}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </UserContext.Provider>
          </ThemeProvider>
        </Router>
      ) : (
        <>
          <Router history={history}>
            <Route exact path="/" component={Landing} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={TermsAndConditions} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Router>
        </>
        // <Authenticator />
      )}
    </>
  );
  //}
};

// export default withAuthenticator(App,{includeGreetings:true});
export default App;
